"use strict";

(function ($, window) {
  // const $vidPlayer = $('.Hero-Video #player');
  var $vidID = $(".Hero-Video").attr("data-videoID");
  // const $window = $(window);
  var deviceIsMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
  var userPaused = false;
  var playerDefaults = {
    autoplay: 1,
    autohide: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    controls: 0,
    disablekb: 1,
    enablejsapi: 0,
    mute: 1,
    loop: 1,
    playlist: $vidID,
    iv_load_policy: 3,
    start: 0,
    wmode: "opaque"
  };
  function onYouTubePlayerAPIReady() {
    var playerDiv = $("#player");
    if ($(".Hero-Image-Video--Youtube")[0]) {
      playerDiv.YTPlayer({
        host: "https://www.youtube-nocookie.com",
        fitToBackground: false,
        playerVars: playerDefaults,
        videoId: $vidID,
        suggestedQuality: "hd720",
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
          onError: onPlayerError
        }
      });
    }
  }
  function playerEvents(playerData) {
    var $modal = $(".modal");
    var $ytPause = $("#yt-pause");
    var $ytPlay = $("#yt-play");
    $ytPause.click(function (e) {
      e.preventDefault();
      userPaused = true;
      playerData.player.pauseVideo();
      $(this).removeClass("active");
      $ytPlay.addClass("active");
      console.log("pause clicked");
    });
    $ytPlay.click(function (e) {
      e.preventDefault();
      userPaused = false;
      playerData.player.playVideo();
      $(this).removeClass("active");
      $ytPause.addClass("active");
      console.log("play clicked");
    });
    $modal.on("shown.bs.modal", function () {
      playerData.player.pauseVideo();
      console.log(playerData.player.getPlayerState());
    });
    $modal.on("hide.bs.modal", function () {
      if (!userPaused) {
        playerData.player.playVideo();
      }
    });
  }
  function onPlayerError(event) {
    console.log("ERROR OCCURRED", event.data);
  }
  function onPlayerStateChange(event) {
    if (event.data === 1) {
      $(".Hero-Image-Video--Youtube").addClass("VideoActive");
      $(".Hero-Video-Controls").addClass("VideoActive");
    }
  }
  function onPlayerReady(event) {
    event.target.mute();
    console.log("onPlayerReady");
    $(".Hero-Content-Video--Youtube").fadeIn(400);
    playerEvents($("#player").data("ytPlayer"));
  }
  if (document.body.clientWidth >= 1024) {
    if (!deviceIsMobile) {
      onYouTubePlayerAPIReady();
    }
  }
})(jQuery, window);