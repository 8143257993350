"use strict";

(function ($) {
  $(".Itineraries-Carousel").slick({
    variableWidth: true,
    slidesToShow: 5,
    prevArrow: '<button type="button" class="u-hide-mobile-only Btn--noStyle slick-prev Carousel-Nav Carousel-Nav-Prev"><span class="icon icon-chevron icon-rotate-left" aria-label="Carousel Navigate Previous"></span></button>',
    nextArrow: '<button type="button" class="u-hide-mobile-only Btn--noStyle slick-next Carousel-Nav Carousel-Nav-Next" aria-label="Carousel Navigate Next"><span class="icon icon-chevron"></span></button>',
    infinite: false,
    slidesToScroll: 1,
    // centerMode: true,
    accessibility: true,
    initialSlide: 0,
    responsive: [{
      breakpoint: 1650,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerMode: true
      }
    }]
  });
  $(".Itineraries-Carousel").on("init", function (event, slick, currentSlide, nextSlide) {
    $(".Itineraries-Carousel-CurrentSlide").html(currentSlide + 1);
  });

  // On before slide change
  $(".Itineraries-Carousel").on("afterChange", function (event, slick, currentSlide, nextSlide) {
    $(".Itineraries-Carousel-CurrentSlide").html(currentSlide + 1);
  });
})(jQuery);