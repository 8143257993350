"use strict";

(function ($, window) {
  var defaultQuestions = $(".Q-And-A-Item-default");
  $.each(defaultQuestions, function (index, value) {
    var text = $(value).find(".Q-And-A-Item-Text");
    var clickable = $(value).find(".Q-And-A-Item-Default-Collapsible-Title-Row");
    var icons = $(clickable).find(".icon");
    clickable.click(function (e) {
      var isMobileSize = $(window).width() < 767;
      if (isMobileSize) {
        text.toggleClass("Q-And-A-Item-Text-Expanded");
        icons.toggleClass("Q-And-A-Item-Icon-Expanded");
      } else {
        e.preventDefault();
      }
    });
  });
})(jQuery, window);