"use strict";

(function ($) {
  // Navbar and dropdowns
  // const dropdowns = $('.dropdown');

  $(document).ready(function () {
    var $navbar = $(".Navigation");
    var $navlist = $(".Navigation .Navigation-List");
    var $navContainer = $(".Navigation-Container");
    var $collapse = $(".Navigation-Collapse");
    var $journeysMenu = $(".Journeys-Menu");
    var $navSlide = $(".Navigation-Desktop-Slide");
    var $window = $(window);
    var isScrolled = false;
    var scrollTop = getScrollTop();
    function getScrollTop() {
      var scrollTopTemp = 126;
      if (document.body.clientWidth < 1024) {
        scrollTopTemp = 97;
      }
      return scrollTopTemp;
    }

    // get Hero height if the hero exists on desktop
    function heroHeight() {
      if (document.body.clientWidth >= 1024) {
        var _heroHeight = "100vh";
        $journeysMenu.css("height", _heroHeight);
      }
    }
    function mobileScrollNav(resized) {
      if ($window.scrollTop() >= scrollTop) {
        if (resized || !isScrolled) {
          $navbar.addClass("Navigation-Scrolled");
          $navlist.addClass("Navigation-List-Scrolled");
          isScrolled = true;
        }
      }
      if ($window.scrollTop() < scrollTop) {
        if (resized || isScrolled) {
          $navbar.removeClass("Navigation-Scrolled");
          $navlist.removeClass("Navigation-List-Scrolled");
          isScrolled = false;
        }
      }
      // console.log("mobileScrollNav", $window.scrollTop(), scrollTop, isScrolled, resized);
    }

    function desktopSlideNav(resized) {
      if ($navbar.hasClass("Navigation-Scrolled")) {
        $navbar.removeClass("Navigation-Scrolled");
        $navlist.removeClass("Navigation-List-Scrolled");
      }
      if ($window.scrollTop() >= scrollTop) {
        if (resized || !isScrolled) {
          $navSlide.addClass("DropIn").removeClass("PullUp");
          isScrolled = true;
        }
      }
      if ($window.scrollTop() < scrollTop) {
        if (resized || isScrolled) {
          $navSlide.addClass("PullUp").removeClass("DropIn");
          isScrolled = false;
        }
      }
      // console.log("desktopSlideNav", $window.scrollTop(), scrollTop, isScrolled, resized);
    }
    // Navigation scroll to apply the fixed header style
    function scrollNav(resized) {
      if (document.body.clientWidth < 1024) {
        mobileScrollNav(resized);
      } else {
        desktopSlideNav(resized);
      }
    }

    // Close dropdowns when screen becomes big enough to switch to open by hover
    function closeMenusOnResize() {
      if (document.body.clientWidth >= 1024) {
        // close mobile nav and mobile dropdowns
        $collapse.addClass("collapse");
        $collapse.removeClass("in");
        $navbar.removeClass("Navigation-Open");
        heroHeight();
        if (!$(".Dropdown").hasClass("Mobile-Dropdown-Active")) {
          $(".Dropdown").addClass("Mobile-Dropdown-Active");
        }
      } else {
        // close journeys nav
        $(".Journeys-Menu").hide();
        $navContainer.removeClass("Navigation-Journeys-Open");
      }
    }

    // Toggle if navbar menu is open or closed
    function toggleMenu() {
      $collapse.toggleClass("collapse");
      $collapse.toggleClass("in");
      $navbar.toggleClass("Navigation-Open");
      $navlist.toggleClass("Navigation-List-Open");
    }

    // CLICK EVENTS

    // Mobile nav toggle
    $(".Navigation-Toggle").click(function (e) {
      e.preventDefault();
      toggleMenu();
    });

    // Journeys Subnav
    $(".Navigation-Journeys-Anchor").click(function (e) {
      e.preventDefault();
      var $this = $(e.currentTarget);
      var index = $this.attr("data-item");
      if (document.body.clientWidth >= 1024) {
        // Desktop Journeys Menu
        var childNav = $("#Navigation-Journeys-" + index);
        // fix for lagging link color
        if (childNav.is(":visible")) {
          childNav.slideUp(function () {
            $navContainer.removeClass("Navigation-Journeys-Open");
            $navContainer.removeClass("Journeys-FadeIn");
          });
        } else {
          $navContainer.addClass("Navigation-Journeys-Open");
          childNav.slideDown(300, function () {
            $navContainer.addClass("Journeys-FadeIn");
          });
        }
      } else {
        // Mobile Journeys Menu
        var ddParent = $this.parent();
        ddParent.toggleClass("Mobile-Dropdown-Active");
      }
    });

    // WINDOW EVENTS

    $window.on("load", function (event) {
      heroHeight();
    });
    $window.on("debouncedresize", function (event) {
      closeMenusOnResize();
      scrollTop = getScrollTop();
      scrollNav(true);
    });
    $window.on("scroll", function (event) {
      scrollNav(false);
    });
  });
})(jQuery);