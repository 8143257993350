"use strict";

(function ($) {
  var componentClass = "InlineCarousel";
  var selectors = {
    inlineCarousel: ".".concat(componentClass, "--js"),
    inlineCarouselSlides: ".".concat(componentClass, "-slides--js")
  };
  var initializeCarousel = function initializeCarousel(index, inlineCarousel) {
    if (inlineCarousel) {
      var inlineCarouselSlides = $(inlineCarousel).find(selectors.inlineCarouselSlides);
      inlineCarouselSlides.slick({
        prevArrow: $(".InlineCarousel-handle--prev"),
        nextArrow: $(".InlineCarousel-handle--next"),
        centerMode: true,
        centerPadding: "15px",
        variableWidth: true,
        slidesToShow: 1
      });
    }
  };
  var initializeCarousels = function initializeCarousels() {
    var inlineCarousels = $(selectors.inlineCarousel);
    inlineCarousels.each(initializeCarousel);
  };
  initializeCarousels();
})(jQuery);