"use strict";

(function ($, window) {
  $(document).ready(function () {
    var form1 = $("#formStep1");
    function resetForm() {
      $("form").each(function (index) {
        var validator = $(this).validate();
        $("form")[index].reset();
        validator.resetForm();
      });
      $("[aria-invalid='true']").removeAttr("aria-invalid");
      $(".Form-Field.error").removeClass("error");
      $(".ContactModal-Row").show();
      $(".Form-Message").hide();
    }
    function showForm() {
      form1.find("select").each(function () {
        if (this.value === "default") {
          $(this).addClass("default");
        } else {
          $(this).removeClass("default");
        }
        $(this).on("change", function () {
          if (this.value === "default") {
            $(this).addClass("default");
          } else {
            $(this).removeClass("default");
          }
        });
      });
    }
    function sanitizeForm() {
      form1.find('input[type="text"], input[type="tel"], input[type="email"], textarea').each(function () {
        $(this).val(function (index, value) {
          return value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        });
      });
    }
    form1.each(function () {
      $(this).validate({
        rules: {
          journeys: {
            required: true
          }
        },
        errorClass: "error",
        submitHandler: function submitHandler() {
          alert("Valid: " + form1.valid());
        },
        errorPlacement: function errorPlacement(error, element) {
          return true;
        },
        highlight: function highlight(element, errorClass, validClass) {
          $(element).closest(".Form-Field").addClass(errorClass);
        },
        unhighlight: function unhighlight(element, errorClass, validClass) {
          $(element).closest(".Form-Field").removeClass(errorClass);
        }
      });
    });
    function writeCookie(key, value, days) {
      var date = new Date();

      // Default at 365 days.
      days = days || 365;

      // Get unix milliseconds at current time plus number of days
      date.setTime(+date + days * 86400000); //24 * 60 * 60 * 1000

      window.document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";
      return value;
    }
    function readCookie(name, c, C, i) {
      c = document.cookie.split("; ");
      var cookies = {};
      for (i = c.length - 1; i >= 0; i--) {
        C = c[i].split("=");
        cookies[C[0]] = C[1];
      }
      return cookies[name];
    }
    function submitForm() {
      sanitizeForm();
      var formObj = {
        tripInterests: []
      };
      form1.find('input:not([type="checkbox"], [type="radio"]), select, textarea').each(function () {
        var name = this.name;
        formObj[name] = $(this).val() || "";
      });
      form1.find('input[name="optIn"]').each(function () {
        formObj["optIn"] = $(this).is(":checked");
      });
      var journeysVal = form1.find('input[name="journeys"]:checked').val();
      formObj.tripInterests.push(journeysVal);
      var customerId = readCookie("customerId");
      if (customerId) {
        formObj["customerId"] = customerId;
      }
      var formData = JSON.stringify(formObj);
      $.ajax({
        type: "POST",
        url: "/alt/apps/fshr/jet/contact.json",
        data: formData,
        success: function success(response) {
          if (!customerId) {
            writeCookie("customerId", response.customerId);
          }
          $(".ContactModal-Row").hide();
          $(".Form-Thanks").fadeIn();
          window.FS.Tealium.trackLink("form_submit", {
            form_name: "Private Jet More Info"
          });
        },
        error: function error() {
          $(".ContactModal-Row").hide();
          $(".Form-Error").fadeIn();
        },
        dataType: "json",
        contentType: "application/json"
      });
    }
    if (form1.length && window.FS && window.FS.recaptcha) {
      var verifyRecaptcha = window.FS.recaptcha.addRecaptcha(form1[0]);
      form1.on("submit", function (e) {
        e.preventDefault();
        if (form1.valid()) {
          verifyRecaptcha(submitForm);
        }
      });
    }
    $("#contactModal").on("hidden.bs.modal", resetForm);
    $("#contactModal").on("show.bs.modal", showForm);
  });
})(jQuery, window);