"use strict";

var initializeYouTube = function initializeYouTube(youtubeVideoElement) {
  var videoID = youtubeVideoElement.getAttribute("data-video-id");
  window.youTubeApiReady(function () {
    new YT.Player(youtubeVideoElement, {
      host: "https://www.youtube-nocookie.com",
      fitToBackground: false,
      height: "100%",
      width: "100%",
      playerVars: {
        autoplay: 0
      },
      videoId: videoID,
      suggestedQuality: "hd720",
      events: {
        onReady: function onReady(event) {
          window.FS.Tealium.onYouTubePlayerReady(event, {
            namePrefix: "jet:"
          });
        }
      }
    });
  });
};
var initThirdPartyVideo = function initThirdPartyVideo() {
  var allYouTubeVideoElements = document.querySelectorAll(".ThirdPartyVideo > div");
  for (var i = 0; i < allYouTubeVideoElements.length; i++) {
    initializeYouTube(allYouTubeVideoElements[i]);
  }
};
initThirdPartyVideo();