"use strict";

// currently we are not using fadeInIterate in the site, but keeping it in case it is needed in the future
// (function($) {
// 	$(document).ready(() => {
// 		$.fn.fadeInIterate = function(duration) {
// 			const len = this.length;
// 			console.log(duration);
// 			this.each(function(i) {
// 				const $this = $(this);
// 				if (!$this.hasClass('fadeIn')) {
// 					setTimeout(function() {
// 						$this.addClass('fadeIn');
// 					}, duration*i);
// 					if (i == len - 1) {
// 						$this.parent().addClass('fadeInComplete');
// 					}
// 				}
// 				});
// 		};
// 	})
// })(jQuery);

// Creates deferred so, other players know when to wait.
window.dfd = {};
window.loadingVideoPlayers = {};
window.videoApiReady = function (type, scriptSrc, loadedCriteria, loadedCbName, cb) {
  window.dfd[type] = window.dfd[type] || $.Deferred();
  window.dfd[type].done(function () {
    cb();
  });
  if (!loadedCriteria() && !window.loadingVideoPlayers[type]) {
    var tag = document.createElement("script"),
      head = document.getElementsByTagName("head")[0];
    tag.src = scriptSrc;
    head.appendChild(tag);
    window.loadingVideoPlayers.youTube = true;

    // Clean up Tags.
    head = null;
    tag = null;
    window[loadedCbName] = function () {
      window[loadedCbName] = null;
      window.dfd[type].resolve("done");
      window.loadingVideoPlayers[type] = false;
    };
  }
};

// Load Youtube API
window.youTubeApiReady = function (cb) {
  window.videoApiReady("youTube", window.location.origin == "file://" ? "http://www.youtube.com/iframe_api" : "//www.youtube.com/iframe_api", function () {
    return typeof YT !== "undefined";
  }, "onYouTubeIframeAPIReady", cb);
};

// Load vimeo API
window.vimeoApiReady = function (cb) {
  window.videoApiReady("vimeo", "/alt/fshr/jet/js/vendor-optional/vimeo-2.1.0.min.js", function () {
    return window.Vimeo && window.Vimeo.Player;
  }, "onVimeoIframeAPIReady", cb);
};