"use strict";

jQuery(function () {
  (function ($, window) {
    var modals = $(".VideoModal");
    modals.each(function () {
      var modal = $(this);
      var videoID = modal.attr("id");
      //const iframe = $("#iframe-" + modalID);
      if (modal.hasClass("VideoModal-Modal-youtube")) {
        window.youTubeApiReady(function () {
          //<iframe class="embed-responsive-item VideoModal-iframe-{{overlaySource}}" id="iframe-{{overlayVideoID}}" width="560" height="349" src="" frameborder="0" webkitallowfullscreen mozallowfullscreen  allowfullscreen></iframe>
          var player = new YT.Player(modal.find(".player")[0], {
            host: "https://www.youtube-nocookie.com",
            fitToBackground: false,
            height: 349,
            width: 560,
            playerVars: {
              autoplay: 0
            },
            videoId: videoID,
            suggestedQuality: "hd720",
            events: {
              onReady: function onReady(event) {
                window.FS.Tealium.onYouTubePlayerReady(event, {
                  namePrefix: "jet:"
                });
              }
            }
          });
          modal.on("shown.bs.modal", function () {
            player.playVideo();
          });

          /* Assign empty url value to the iframe src attribute when
          modal hide, which stop the video playing */
          modal.on("hide.bs.modal", function () {
            player.pauseVideo();
          });
        });
      } else if (modal.hasClass("VideoModal-Modal-vimeo")) {
        window.vimeoApiReady(function () {
          var player = new window.Vimeo.Player(modal.find(".player")[0], {
            id: videoID,
            width: 560,
            height: 349
          });
          window.FS.Tealium.onVimeoPlayerReady(player, {
            namePrefix: "jet:"
          });
          modal.on("shown.bs.modal", function () {
            player.play();
          });

          /* Assign empty url value to the iframe src attribute when
          modal hide, which stop the video playing */
          modal.on("hide.bs.modal", function () {
            player.pause();
          });
        });
      }
    });
  })(jQuery, window);
});